import React from 'react';
import Header from '../components/Header';

const Voir = () => {
    return (
        <div>
            <Header/>
            voir
        </div>
    );
};

export default Voir;