import React from 'react';
import Header from '../components/Header';
import { Link } from 'react-router-dom';

const About = () => {
    return (
        <div>
                     <Header/>

            About
            <Link to="/Voir">Voir</Link>
        </div>
    );
};

export default About;