import React from "react";
import Header from "../components/Header";
import "../components/styles/Home.css";
import Nav from "../components/Nav";

const Home = () => {
  const image1 = "/image/image1.jpg";
  const image2 = "/image/image2.jpg";
  const imgg = "/image/imgg.png";
  const vector = "/image/group5.svg";
  const frame3 = "/image/frame3.svg";
  const frame4 = "/image/frame4.svg";
  const frame5 = "/image/frame5.svg";
  const frame6 = "/image/frame6.svg";
  const frame10 = "/image/frame10.svg";
  const frame11 = "/image/frame11.svg";
  const frame12 = "/image/frame12.svg";
  const frame13 = "/image/frame13.svg";
  const cercler = "/image/cercler.svg";
  const frame1 = "/image/Group1.svg";
  return (
    <div>
      <Nav />

<body>
<div className="container-fluid hero">
        <div className="huu">
            <div className="setexte">
              <h3 className="herotexte">
                Chat Apps Keep
                Conversation Flowing
              </h3>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing
                <br /> elit. Alias esse id, cum .
              </p>
              <button class="btn btn-danger buy" type="submit">
                Buy More
              </button>
            </div>
            <div className="secc">

              <img src={frame1} alt="" className="frame1"/>
              <img src={frame3} alt="" className="frame3"/>
              <img src={frame4} alt="" className="frame4"/>
              <img src={frame5} alt="" className="frame5"/>
              <img src={frame6} alt="" className="frame6"/>
              {/* <img src={frame1} alt="" className="frame1"/>
              <img src={frame1} alt="" className="frame1"/>
              <img src={frame1} alt="" className="frame1"/> */}
              {/* <div className="carte">
                <div className="col-12">
                  <div className="row">
                    <div className="col-3">
                      <img src={image1} alt="" className="top-img" />
                    </div>

                    <div className="col-9">
                      <div className="row">
                        <div className="col-6 line1"></div>

                        <div className="col-6 line2"></div>
                      </div>

                      <div className="row">
                        <div className="col-4 lineblue"></div>

                        <div className="col-6 line4"></div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <img src={image2} alt="" className="imgp" />
                  </div>

                  <div className="content">
                    <h3 className="texte"></h3>
                    <h3 className="texte"></h3>
                    <h3 className="texte"></h3>
                    <h3 className="texte"></h3>
                    <h3 className="texte"></h3>
                    <h3 className="texte"></h3>
                    <h3 className="texte"></h3>
                  </div>
                </div>
              </div> */}
{/* 
              <div className="carte2">
                <div className="col-12">
                  <div className="row">
                    <div className="col-3">
                      <img src={image1} alt="" className="top-img2" />
                       <h2>koooooooo</h2> 
                    </div>

                    <div className="col-9">
                      <div className="row">
                        <div className="col-6 line12"></div>

                        <div className="col-6 line22"></div>
                      </div>

                      <div className="row">
                        <div className="col-4 lineblue2"></div>

                        <div className="col-6 line42"></div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <img src={image2} alt="" className="imgp2" />
                  </div>

                  <div className="content2">
                    <h3 className="texte2"></h3>
                    <h3 className="texte2"></h3>
                    <h3 className="texte2"></h3>
                    <h3 className="texte2"></h3>
                    <h3 className="texte2"></h3>
                  </div>
                </div>
              </div> */}

              {/* <div className="carte3">
                <div className="col-12">
                  <div className="row">
                    <div className="col-3">
                      <img src={image1} alt="" className="top-img3" />
                       <h2>koooooooo</h2> 
                    </div>

                    <div className="col-9">
                      <div className="row">
                        <div className="col-6 line13"></div>

                        <div className="col-6 line23"></div>
                      </div>

                      <div className="row">
                        <div className="col-4 lineblue3"></div>

                        <div className="col-6 line43"></div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <img src={image2} alt="" className="imgp3" />
                  </div>

                  <div className="content3">
                    <h3 className="texte3"></h3>
                    <h3 className="texte3"></h3>
                    <h3 className="texte3"></h3>
                    <h3 className="texte3"></h3>
                  </div>
                </div>
              </div> */}

              {/* <div className="carte4">
                <div className="col-12">
                  <div className="row">
                    <div className="col-3">
                      <img src={image1} alt="" className="top-img4" />
                       <h2>koooooooo</h2> 
                    </div>

                    <div className="col-9">
                      <div className="row">
                        <div className="col-6 line14"></div>

                        <div className="col-6 line24"></div>
                      </div>

                      <div className="row">
                        <div className="col-4 lineblue4"></div>

                        <div className="col-6 line44"></div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <img src={image2} alt="" className="imgp4" />
                  </div>
                </div>
              </div> */}

              {/* <div className="carte5">
                <div className="col-12">
                  <div className="row">
                    <div className="col-3">
                      <img src={image1} alt="" className="top-img4" />
                       <h2>koooooooo</h2> 
                    </div>

                    <div className="col-9">
                      <div className="row">
                        <div className="col-6 line14"></div>

                        <div className="col-6 line24"></div>
                      </div>

                      <div className="row">
                        <div className="col-4 lineblue4"></div>

                        <div className="col-6 line44"></div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <img src={image2} alt="" className="imgp4" />
                  </div>
                </div>
              </div> */}
            </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="sec1">
          <p className="titlesec1">Show Chatroll Few Famous Features</p>
          <div className="lin"></div>
          {/* <span className="lin"></span> */}
          <p className="content-sec1">
            Lorem ipsum, dolor sit amet consectetur adipisicing <br />
            elit. Eius a quibusdam aut facilis modidolores quos
            <br /> ut voluptatem. Quasi, suscipit suscipit suscipit.
          </p>
        </div>

        <div className="container">
          <div className="col-12 sec1-1">
            <div className="row">
              <div className="col-md-4 colomn">
                <div className="content">
                  <img src={image1} alt="" className="image-sec1" />
                  <h2 className="title-content">Global number coverage</h2>
                  <p className="paragraphe-content">
                    Lorem ipsum dolor sit amet, consectetur
                    <br /> adipisicing elit. Ipsa aspernatur andaedu,
                    <br /> debitis maiores q fugit nam sequi aliquid
                    <br /> reiciendis sapiente dolorum?
                  </p>
                </div>
              </div>

              <div className="col-md-4 colomn">
                <div className="content">
                  <img src={image1} alt="" className="image-sec1" />
                  <h2 className="title-content">world wild delivery</h2>
                  <p className="paragraphe-content">
                    Lorem ipsum dolor sit amet, consectetur
                    <br /> adipisicing elit. Ipsa aspernatur andaedu,
                    <br /> debitis maiores q fugit nam sequi aliquid
                    <br /> reiciendis sapiente dolorum?
                  </p>
                </div>
              </div>

              <div className="col-md-4 colomn">
                <div className="content">
                  <img src={image1} alt="" className="image-sec1" />
                  <h2 className="title-content">regulatory complience</h2>
                  <p className="paragraphe-content">
                    Lorem ipsum dolor sit amet, consectetur
                    <br /> adipisicing elit. Ipsa aspernatur andaedu,
                    <br /> debitis maiores q fugit nam sequi aliquid
                    <br /> reiciendis sapiente dolorum?
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 colomn">
                <div className="content">
                  <img src={image1} alt="" className="image-sec1" />
                  <h2 className="title-content">Global number coverage</h2>
                  <p className="paragraphe-content">
                    Lorem ipsum dolor sit amet, consectetur
                    <br /> adipisicing elit. Ipsa aspernatur andaedu,
                    <br /> debitis maiores q fugit nam sequi aliquid
                    <br /> reiciendis sapiente dolorum?
                  </p>
                </div>
              </div>

              <div className="col-md-4 colomn">
                <div className="content">
                  <img src={image1} alt="" className="image-sec1" />
                  <h2 className="title-content">world wild delivery</h2>
                  <p className="paragraphe-content">
                    Lorem ipsum dolor sit amet, consectetur
                    <br /> adipisicing elit. Ipsa aspernatur andaedu,
                    <br /> debitis maiores q fugit nam sequi aliquid
                    <br /> reiciendis sapiente dolorum?
                  </p>
                </div>
              </div>

              <div className="col-md-4 colomn">
                <div className="content">
                  <img src={image1} alt="" className="image-sec1" />
                  <h2 className="title-content">regulatory complience</h2>
                  <p className="paragraphe-content">
                    Lorem ipsum dolor sit amet, consectetur
                    <br /> adipisicing elit. Ipsa aspernatur andaedu,
                    <br /> debitis maiores q fugit nam sequi aliquid
                    <br /> reiciendis sapiente dolorum?
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="col-12 sec3">
          <div className="row">
            <div className="col-md-5 sec33">
              <h2 className="title-sec3">
                No <span className="colorred">More</span> Lost Receipts And<br />
                Tedious <span className="colorred">Poperwork.</span>
              </h2>
              <p className="paragraphe-sec3">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Natus
                mollitia, autem nihil dicta, necessitatibus modi fugiat ipsum
                officia quis alias deleniti ab quam! Quidem nostrum temporibus
                enim? Reprehenderit, cum laborum?
                officia quis alias deleniti ab quam! Quidem nostrum temporibus
                enim? Reprehenderit, cum laborum?
                officia quis alias deleniti ab quam! Quidem nostrum temporibus
                enim? Reprehenderit, cum laborum?
              </p>

              <button className="btn btn-primary">View More</button>
            </div>
            <div className="col-md-6 lololo">

              <img src={frame10} alt="" className="frame10"/>
              <img src={frame11} alt="" className="frame11"/>
              <img src={frame12} alt="" className="frame12"/>
                {/* <div className="col-12 cardsec3">
                    <div className="row">
                            <div className="col-2">
                                <img src={image2} alt="" className="imgcardsec3"/> 
                            </div>

                            <div className="col-9">
                                <h5 className="titlecardsec3">Come to see</h5>
                                <p className="paragraphcardsec3">lorem lore lor</p>
                        </div>
                    </div>
                    <img src={image1} alt="" className="imgpcardsec3"/>
                    <h2 className="titlepcardsec3">Lorem quis alias deleniti</h2>
                </div>

                <div className="col-12 card2sec3">
                    <div className="row linecard2sec3">
                            <div className="col-2">
                                <img src={image2} alt="" className="imgcardsec3"/> 
                            </div>

                            <div className="col-7">
                                <h5 className="titlecardsec3">Come to see</h5>
                                <p className="paragraphcardsec3">lorem lore lor lorem lorem</p>
                            </div>

                            <div className="col-2">
                                <div className="circular">2</div> 
                            </div>
                    </div>
                    
                    <div className="row linecard2sec3">
                            <div className="col-2">
                                <img src={image2} alt="" className="imgcardsec3"/> 
                            </div>

                            <div className="col-7">
                                <h5 className="titlecardsec3">Come to see</h5>
                                <p className="paragraphcardsec3">lorem lore lor lorem lorem</p>
                            </div>

                            <div className="col-2">
                                <div className="circular">2</div> 
                            </div>
                    </div>

                    <div className="row linecard2sec3">
                            <div className="col-2">
                                <img src={image2} alt="" className="imgcardsec3"/> 
                            </div>

                            <div className="col-7">
                                <h5 className="titlecardsec3">Come to see</h5>
                                <p className="paragraphcardsec3">lorem lore lor lorem lorem</p>
                            </div>

                            <div className="col-2">
                                <div className="circular">2</div> 
                            </div>
                    </div>
                   
                </div>
                <div className="col-12 cardsec4">
                    <div className="row">
                            <div className="col-2">
                                <img src={image2} alt="" className="imgcardsec3"/> 
                            </div>

                            <div className="col-5">
                                <h5 className="titlecardsec3">Come to see</h5>
                                <p className="paragraphcardsec3">lorem lore lor</p>
                        </div>

                        <div className="col-5">
                                <img src={image2} alt="" className="img3cardsec3"/>       
                                <img src={image2} alt="" className="img3cardsec3"/>       
                                <img src={image2} alt="" className="img3cardsec3"/>       
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-7">
                                    <img src={image2} alt="" className="img4cardsec3"/>       
                                    <img src={image2} alt="" className="img4cardsec3"/>       
                                    <img src={image2} alt="" className="img4cardsec3"/>       
                                    <img src={image2} alt="" className="img4cardsec3"/>    
                                </div>
                                
                                <div className="col-5">
                                    <img src={image2} alt="" className="img4cardsec3"/>       
                                    <img src={image2} alt="" className="img4cardsec3"/>       
                                    <img src={image2} alt="" className="img4cardsec3"/> 
                                </div>
                             </div>
                        </div>   
                    </div>
                </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="col-12 sec5">
            <div className="row">
                <div className="col-md-6 kokoko">
                    <img src={imgg} alt="" className="imgg"/>
                      
                    <img src={frame13} alt="" className="frame13"/>

                    {/* <div className="col-12 cardsec5">
                        <img src={image1} alt="" className="imgpcardsec5"/>
                        <h5 className="titlecardsec5"> Come to seevo </h5>
                        <p className="paragraphcardsec3">lorem lore lorlorem lore lor</p>
                        <h5 className="title2cardsec5"> Random free</h5>
                        <h5 className="title3cardsec5"> For Everyone </h5>
                        <h5 className="title4cardsec5"> November1209 </h5>
                         <h2 className="titlepcardsec3">Lorem quis alias deleniti</h2> 
                    </div> */}
                </div>

                <div className="col-md-5 sec5-2">
                        <h2 className="title-sec3">
                        No <span className="colorred">More</span> Lost Receipts And<br />
                        Tedious <span className="colorred">Poperwork.</span>
                    </h2>
                    <p className="paragraphe-sec3">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Natus
                        mollitia, autem nihil dicta, necessitatibus modi fugiat ipsum
                        officia quis alias deleniti ab quam! Quidem nostrum temporibus
                        enim? Reprehenderit, cum laborum?
                        officia quis alias deleniti ab quam! Quidem nostrum temporibus
                        enim? Reprehenderit, cum laborum?
                        officia quis alias deleniti ab quam! Quidem nostrum temporibus
                        enim? Reprehenderit, cum laborum?
                    </p>

                    <button className="btn btn-primary">View More</button>
                </div>
            </div>
        </div>
      </div>

      <div kokkkkk>
        <img src={vector} alt="" className="vector"/>
        {/* <img src={cercler} alt="" className="cercler"/> */}
        <h2 className="jojo">lorem lorem lorem</h2>
        <h2 className="jojo1">lorem lorem </h2>
      </div>
  
</body>
    </div>

  );
};

export default Home;
