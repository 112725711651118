import React from 'react';
import Home from '../../pages/Home';
import About from '../../pages/About';

import Voir from '../../pages/Voir';
import {
    BrowserRouter as Router,
    Route,Routes,
  
  } from "react-router-dom";
import Nav from '../Nav';


const AppRoutes = () => {
    return (
        <Router>
           <Routes>
                <Route path="/Nav" element={<Nav />}/>
                <Route path="/" element={<Home />}/>
                <Route path="/About" element={<About/>}/>
                <Route path="/Voir" element={<Voir/>}/>
           </Routes>
        </Router>
    );
};

export default AppRoutes;