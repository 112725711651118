import React from 'react';
import Routes from "./components/Routes";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './components/styles/Header.css';
import './components/styles/Home.css';



const App = () => {

  return (
  <div>
    <Routes />
    <ToastContainer />
  </div>    
  );
}

export default App;
