import React, {useState} from 'react'
import '../components/styles/Nav.css';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../components/styles/Nav.css';


const Nav = () => {
    const [active, setActive] = useState('dropdown_menu')
    // function to tooggle navbar
    const showNav = ()=>{
      setActive('dropdown_menu open')
    }
  
      // function to remove navbar
      const removeNav = ()=>{
        setActive('dropdown_menu')
      }
    
  return (
    <body>
            <header>

<div className='navbar'>
    <div className="logo"><a href="#">web dev</a></div>
    <ul className='links'>
        <li><a href="#">Home</a></li>
        <li><a href="#">About</a></li>
        <li><a href="#">Service</a></li>
        <li><a href="#">Contact</a></li>
    </ul>
    <a href="#" className="action_btn">Get started</a>
    <div onClick={showNav}  className="toggle_btn">
        <FontAwesomeIcon icon={faBars} />            
    </div>
</div>

<div className={active}>
        <li><a href="#" onClick={removeNav}>Home</a></li>
        <li><a href="#" onClick={removeNav}>About</a></li>
        <li><a href="#" onClick={removeNav}>Service</a></li>
        <li><a href="#" onClick={removeNav}>Contact</a></li>
        <li><a href="#" onClick={removeNav} className="action_btn">Get started</a></li>

        {/* <div onClick={removeNavbar} className='closeNavbar'>
            <AiFillCloseCircle className='icon'/>
          </div>
        </div>


        <div onClick={showNav}
         className="toggleNavbar">
          <TbGridDots className='icon'/>
        </div> */}
</div>
</header>
    </body>

  )
}

export default Nav
