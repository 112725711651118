import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import '../components/styles/Header.css';


const Header = () => {
   
    return (
        <nav class="navbar navbar-expand-lg bg-body-tertiary navigatebar">
        <div class="container-fluid navigatebar">
          <a class="navbar-brand logo navigatecontent" href="#">Logo</a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0 liste">
              <li class="nav-item">
                <a class="nav-link navigatecontent" aria-current="page" href="#">Home</a>
              </li>
              <li class="nav-item">
                <a class="nav-link navigatecontent" href="#">Link</a>
              </li>
              <li class="nav-item">
                <a class="nav-link navigatecontent" href="#">Contact</a>
              </li>
              <li class="nav-item">
                <a class="nav-link navigatecontent" href="#">about</a>
              </li>
              <li class="nav-item">
                <a class="nav-link navigatecontent" href="#">testimonial</a>
              </li>

              <li class="nav-item">
                <a class="nav-link navigatecontent" aria-disabled="true">Disabled</a>
              </li>
            </ul>
            <form class="d-flex bout" role="search">
            <button class="btn btn navigatecontent" type="submit">Sign in</button>
            <button class="btn btn-danger" type="submit">Sign up</button>
            </form>
          </div>
        </div>
      </nav>
       
    );
};

export default Header;